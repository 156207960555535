import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from "reactstrap";
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';

import _ from 'lodash'

import {
  request_two_factor_auth_code,
  submit_two_factor_auth,
} from 'util/APICall'

//Import Home Button
import AccountHomeButton from "./account-home-button";

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      deliveryChannel: 'sms', // 'sms' or 'email'
      errorMessage: "",
      topMessage: "",
      requestedNewCode: false,
      loading: false,
    };
  }

  componentWillMount() {
    document.body.classList.add("bg-account-pages");
    document.body.classList.add("py-4");
    document.body.classList.add("py-sm-0");

    this.requestNewCode(false)
  }

  onClickSubmit = (event, values) => {
    const two_factor_code = values.two_factor_code

    this.setState({
      requestedNewCode: false,
      error: false,
      errorMessage: "",
      loading: true
    })

    submit_two_factor_auth(
      this.props.email,
      this.props.password,
      two_factor_code
    ).then(response => {
      if (response.data.data['success_status'] === true) {
        window.localStorage.setItem('auth_token', response.data.data["token_key"]);

        // if the user has a two factor auth exemption code, store it in local storage
        if ('two_factor_exemption_code' in response.data.data)
          window.localStorage.setItem(
            'two_factor_exemption_code',
            response.data.data["two_factor_exemption_code"]
          );

        window.location.replace("/");
      } else {
        this.setState({
          error: true,
          errorMessage: response.data.data.message,
          loading: false
        })
      }
    }).catch(err => {
      const actionRequired = _.get(err.response, 'data.data.action_required', null)

      if (actionRequired === 'two_factor_auth_code_expired') {
        this.requestNewCode(true)
        this.setState({
          error: true,
          errorMessage: "Two factor authentication code expired. Requesting a new code...",
        })

        return
      }

      let errMsg = ""
      if (err && err !== null && err.response && err.response !== null && err.response.data.data !== null) {
        errMsg = err.response.data.data.message
      } else {
        errMsg = err.message
      }
      this.setState({ error: true, errorMessage: errMsg, loading: false })
    })
  }

  requestNewCode = (requestedNewCode) => {
    this.setState({
      requestedNewCode,
      error: false,
      errorMessage: "",
      loading: true,
      topMessage: "Requesting two factor authentication code..."
    })

    request_two_factor_auth_code(
      this.props.email,
      this.props.password,
      this.state.deliveryChannel
    ).then(
      response => {
        this.setState({
          topMessage: response.data.data.message,
          loading: false,
          error: false,
          errorMessage: "",
        })
      }).catch(err => {
        let errMsg = ""
        if (err && err !== null && err.response && err.response && err.response.data.data) {
          errMsg = err.response.data.data.message
        } else {
          errMsg = err.message
        }
        this.setState({
          error: true,
          errorMessage: errMsg,
          loading: false,
          topMessage: "",
        })
      })
  }

  switchChannel = (deliveryChannel) => {
    this.setState({ deliveryChannel }, () => this.requestNewCode(true))
  }

  debouncedClickSubmitHandler = _.debounce(this.onClickSubmit, 750)
  debouncedClickSwitchChannelHandler = _.debounce(this.switchChannel, 750)
  debouncedClickRequestNewCodeHandler = _.debounce(this.requestNewCode, 750)

  render() {
    return (
      <React.Fragment>

        {/* render home button */}
        <AccountHomeButton />

        <section className="height-100vh">
          <div className="display-table">
            <div data-testid="signin_form" className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="account-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold"><a data-testid="link_logo_go_to_landing" href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                          <p className="text-muted">Enter your two factor authentication code to login</p>
                          <p className="text-muted" data-testid="two_factor_code_top_message">{this.state.topMessage}</p>
                        </div>
                        <div className="p-3">
                          <AvForm onValidSubmit={this.debouncedClickSubmitHandler}>
                            <AvGroup className="form-group">
                              <AvField data-testid="two_factor_code_input" name="two_factor_code" type="number" className="form-control text-center" id="two_factor_code" placeholder="Two Factor Authentication Code" required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter a Two Factor Authentication Code"
                                  },
                                }} />
                            </AvGroup>

                            {
                              this.state.error &&
                              <Alert color="danger">
                                {this.state.errorMessage}
                              </Alert>
                            }

                            {
                              this.state.requestedNewCode
                              && !this.state.error
                              && !this.state.loading
                              &&
                              <Alert color="success">
                                A new code has been sent to your {this.state.deliveryChannel == 'sms' ? 'mobile number' : 'email address'}.
                              </Alert>
                            }

                            <div className="mt-3">
                              <Button disabled={this.state.loading} data-testid="submit_button" type="submit" className="btn btn-custom btn-block">Submit Code</Button>
                            </div>
                            {
                              !this.state.loading &&
                              [
                                <div className="mt-4 mb-0 text-center">
                                  <p className="mb-0">
                                    Didn't receive a code?&nbsp;
                                    <a style={this.state.loading == true ? { pointerEvents: "none" } : { cursor: "pointer" }}
                                      onClick={() => this.debouncedClickRequestNewCodeHandler(true)}
                                      className="text-danger">
                                      Request a new code
                                    </a>
                                  </p>
                                </div>,
                                <div className="mt-4 mb-0 text-center">
                                  <p className="mb-0">
                                    <a style={this.state.loading == true ? { pointerEvents: "none" } : { cursor: "pointer" }}
                                      onClick={() => this.debouncedClickSwitchChannelHandler(this.state.deliveryChannel === 'sms' ? 'email' : 'sms')}>
                                      <i className={"mdi " + (this.state.deliveryChannel === 'sms' ? "mdi-email" : "mdi-message")} />
                                      &nbsp;Want to receive the code via {this.state.deliveryChannel === 'sms' ? 'Email' : 'SMS'}?
                                    </a>
                                  </p>
                                </div>,
                              ]
                            }
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>

    );
  }
}

export default TwoFactorAuth;
