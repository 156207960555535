import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Alert } from "reactstrap";

import _ from 'lodash'

import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { login } from 'util/APICall'
import { MixpanelActions } from 'util/Mixpanel';
import TwoFactorAuth from './two_factor_auth'
import ResetPhoneNumber from './reset_phone_number'

//Import Home Button
import AccountHomeButton from "./account-home-button";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      pageDisplayed: "login",
      twoFactorAuthMessage: "",
      email: "",
      password: ""
    };
  }

  componentWillMount() {
    document.body.classList.add("bg-account-pages");
    document.body.classList.add("py-4");
    document.body.classList.add("py-sm-0");
  }

  onClickLogin = (event, values) => {
    const email = values.username
    const password = values.userpassword

    // get the two factor auth exemption code, if any
    const twoFactorAuthExemption = window.localStorage.getItem(
      'two_factor_exemption_code',
      null
    );

    login(email, password, twoFactorAuthExemption).then(response => {
      const actionRequired = _.get(response.data, 'data.action_required', null)

      // if the user needs to reset their phone number,
      // send them to the reset phone number page
      if (actionRequired === 'reset_phone_number') {
        this.setState({
          pageDisplayed: "reset_phone_number",
          email: email,
          password: password
        })

        return
      }


      if (response.data.data['success_status'] === true) {
        const userId = response.data.data['user_id']
        const profileId = response.data.data['profile_id']

        MixpanelActions.loginEvent(userId, profileId, email)

        window.localStorage.setItem(
          'auth_token',
          response.data.data["token_key"]
        );

        // store the two factor auth exemption code, if any
        if ('two_factor_exemption_code' in response.data.data)
          window.localStorage.setItem(
            'two_factor_exemption_code',
            response.data.data['two_factor_exemption_code']
          )

        // read next param and send user to it
        const windowUrl = window.location.href;

        // extract the query params
        const [hash, query] = windowUrl.split('#')[1].split('?')

        // build the object
        const params = new URLSearchParams(query)

        // and extract the next 'key'
        const next = params.get('next')

        if (next != null)
          window.location.replace(decodeURIComponent(next));
        else
          window.location.replace("/");

      } else {
        this.setState({
          error: true,
          errorMessage: response.data.data['message'],
          loading: false,
          twoFactorAuth: false,
          twoFactorAuthMessage: ""
        })
      }
    }).catch(err => {
      const actionRequired = _.get(err.response, 'data.data.action_required', null)
      let errMsg = _.get(err.response, 'data.data.message', null)

      if (actionRequired === 'two_factor_auth_code_required') {
        this.setState({
          pageDisplayed: "two_factor_auth",
          twoFactorAuthMessage: errMsg,
          email: email,
          password: password
        })
      } else {
        if (errMsg == null)
          errMsg = err.message
      }

      if (errMsg != "") {
        this.setState({ error: true, errorMessage: errMsg, loading: false })
      }
    })
  };

  debouncedClickSubmitHandler = _.debounce(this.onClickLogin, 750)

  render() {
    if (this.state.pageDisplayed === "two_factor_auth")
      return <TwoFactorAuth
        topMessage={this.state.twoFactorAuthMessage}
        email={this.state.email}
        password={this.state.password}
      />

    if (this.state.pageDisplayed === "reset_phone_number")
      return <ResetPhoneNumber
        email={this.state.email}
        password={this.state.password}
        onSuccess={() => this.onClickLogin(null, { username: this.state.email, userpassword: this.state.password })}
      />

    return (
      <React.Fragment>

        {/* render home button */}
        <AccountHomeButton />

        <section className="height-100vh">
          <div className="display-table">
            <div data-testid="signin_form" className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="account-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold"><a data-testid="link_logo_go_to_landing" href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                          <p className="text-muted">Sign in to continue to Aura Commodities</p>
                        </div>
                        <div className="p-3">
                          <AvForm onValidSubmit={this.debouncedClickSubmitHandler}>
                            <AvGroup className="form-group">
                              <Label data-testid="email_label" for="username">Business Email</Label>
                              <AvField data-testid="email_input" name="username" type="text" className="form-control" id="username" placeholder="Business Email" required
                                errorMessage=""
                                validate={{
                                  required: { value: true, errorMessage: "Please enter a business email address" }
                                }} />
                            </AvGroup>

                            <AvGroup className="form-group">
                              <Label data-testid="password_label" for="userpassword">Password</Label>
                              <AvField data-testid="password_input" name="userpassword" type="password" className="form-control" id="userpassword" placeholder="Password" required
                                errorMessage=""
                                validate={{
                                  required: { value: true, errorMessage: "Please enter a password" }
                                }} />
                            </AvGroup>

                            {this.state.error == true && <Alert color="danger">
                              {this.state.errorMessage}
                            </Alert>
                            }

                            <div className="mt-3">
                              <Button data-testid="submit_button" type="submit" className="btn btn-custom btn-block">Log In</Button>
                            </div>

                            <div className="mt-4 mb-0 text-center">
                              <a data-testid="password_reset" href="/accounts/password_reset/" className="text-dark"><i className="mdi mdi-lock"></i> Forgot your password?</a>
                            </div>

                            <div className="mt-4 mb-0 text-center">
                              <p className="mb-0">Don't have an account? <Link data-testid="link_to_signup" to="/signup" className="text-danger">Request a Demo</Link></p>
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>

    );
  }
}

export default Login;
